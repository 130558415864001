import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Gebruik de juiste hook
import { useRequestService } from '../services/RequestService';

function SignupPage() {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState('ROLE_CUSTOMER');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Gebruik `navigate` in plaats van `history`
  const requestService = useRequestService();

  const handleSignup = async () => {
    setError(''); // Reset error message bij nieuwe poging

    // Validatie voor lege velden en wachtwoordvergelijking
    if (!fullName || !email || !password || !confirmPassword) {
      setError('Please fill in all fields.');
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await requestService.post('auth/signup', {
        fullName,
        email,
        password,
        role
      });

      console.log('Signup successful:', response.data);
      navigate('/home'); // Verplaats gebruiker naar home bij succes

    } catch (error) {
      // Toon error response of fallback error message
      setError(error.response?.data || 'Signup failed. Please try again.');
      console.error('Signup failed:', error);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 m-4">
      <div className="border rounded-lg shadow-md p-4">
        <form className="login-panel">
          <h2 className="mb-4 text-sky-900 text-center">Sign Up Page</h2>
          {/* Render error message if exists */}
          {error && <p className="text-danger">{error}</p>}

          <input id='fullName' placeholder="Full Name" value={fullName} type='text'
            className="border appearance-none  rounded m-3 p-2 text-gray-700 leading-tight focus:outline-none bg-white border-sky-500 focus:bg-sky-50 focus:border-sky-700"
            onChange={(e) => setFullName(e.target.value)} />
          <input placeholder='Email Address' id='email' value={email} type='email'
            className="border appearance-none  rounded m-3 p-2 text-gray-700 leading-tight focus:outline-none bg-white border-sky-500 focus:bg-sky-50 focus:border-sky-700"
            onChange={(e) => setEmail(e.target.value)} />
          <input placeholder='Password' id='password' type='password' value={password}
            className="border appearance-none  rounded m-3 p-2 text-gray-700 leading-tight focus:outline-none bg-white border-sky-500 focus:bg-sky-50 focus:border-sky-700"
            onChange={(e) => setPassword(e.target.value)} />
          <input placeholder='Confirm Password' id='confirmPassword' type='password'
            className="border appearance-none  rounded m-3 p-2 text-gray-700 leading-tight focus:outline-none bg-white border-sky-500 focus:bg-sky-50 focus:border-sky-700"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)} />
          <label className="form-label mb-1">Role:</label>
          <select className="form-select mb-4" value={role} onChange={(e) => setRole(e.target.value)}>
            <option value="ROLE_CUSTOMER">User</option>
          </select>
          <button className="shadow bg-sky-800 hover:bg-sky-700 focus:shadow-outline focus:outline-none text-white text-md m-3 p-2 rounded"
            type="button"
            onClick={handleSignup}>Sign Up
          </button>

          <div className="text-center text-sm">
            <p>Already Registered? <a href="/">Login</a></p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignupPage;

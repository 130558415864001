import { useEffect, useState } from 'react';

import ScoreDoughnut from '../components/ScoreDoughnut';
import { useAccountService } from '../services/AccountService';

function refreshPage() {
  setTimeout(() => {
    window.location.reload(false);
  }, 500);
  console.log('swipa page reload');
}

export default function SwipeCongrats() {
  const accountService = useAccountService();
  const [userStats, setUserStats] = useState();

  useEffect(() => {
    if (!userStats) try {
      accountService.getStats().then(stats => setUserStats(stats));
    } catch (ex) {
      console.error("Failed to fetch stats.", ex);
    }
  });

  return (
    <div style={{ textAlign: 'center', padding: '20px' }} className="flex flex-col items-center justify-center">

      {userStats && (<>
        <ScoreDoughnut scoredBuildings={userStats.scoredBuildings} upvotedBuildings={userStats.upvotedBuildings} downvotedBuildings={userStats.downvotedBuildings} className="w-5/8" />
        </>)}
        <h1 className="text-2xl text-sky-800 apptitle font-semibold  pt-4">Waauw</h1>
        <p className="text-lg text-sky-800 ">Je bent op weg een echte GevelGeek te worden.</p>

      <div className="flex w-full justify-center pt-2">
        <button onClick={refreshPage} className="shadow bg-sky-800 hover:bg-sky-700 focus:shadow-outline focus:outline-none text-white text-md m-3 p-2 pz-4 font-semibold  rounded">Verder Swipen</button>
      </div>
    </div>
  );
}

import { useState } from 'react';
import RequestService from './RequestService';
import StorageService from './StorageService';

/**
 * 
 */
export default class BuildingService {
  _token;
  _requestService;
  storageService;

  constructor(requestService) {
    this._requestService = requestService;
    this.storageService = new StorageService();
  }

  get requestService() {
    return this._requestService || (this._requestService = new RequestService());
  }

  async getGallery(page) {
    return await this.requestService.get("buildings/gallery", { page: page })
  }


}


/**
 * 
 * @returns {BuildingService} Building service.
 */
export function useBuildingService() {
  const [buildingService] = useState(new BuildingService());
  return buildingService;
}
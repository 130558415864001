import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import InfiniteScroll from "react-infinite-scroll-component";
import { Puff } from "react-loader-spinner";
import { useBuildingService } from "../services/BuildingService";
import GalleryEmpty from "../components/GalleryEmpty";

function GalleryPage() {

  const [dorples, setDorples] = useState([]); // Hier slaan we de data op
  const [hasMore, setHasMore] = useState(true);
  const [index, setIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const buildingService = useBuildingService();

  const fetchMoreData = () => {
      if (!isLoading && hasMore) {
        setIsLoading(true);

        buildingService.getGallery(index).then(data => {
          setDorples((prevDorples) => [...prevDorples, ...data.content]);
          setHasMore(data.content.length > 0);
          setIndex((prevIndex) => prevIndex + 1);
          setIsLoading(false);
        }).catch(error => {
          console.error("Error fetching data:", error)
          setIsLoading(false);
        });
      } else console.log("I'm already fetching data;");
  };

  useEffect(fetchMoreData); // Begin fetching data.

  return (
    <div id="gallery-container">
      {
        dorples && !dorples.length && !hasMore ? <GalleryEmpty /> :
      <InfiniteScroll
        dataLength={dorples.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <Puff
            visible={true}
            height="70"
            width="70"
            color="#0C4A6E"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        }
        scrollThreshold={0.8}
        className="grid grid-cols-3 gap-2 p-2 sm:grid-cols-3 md:grid-cols-3"
        scrollableTarget="gallery-container"
      >
        {dorples &&
          dorples.map((dorple, index) => (
            <div className="gallery-tile" key={index}>
              <Link
                to={`/gallerydetail`}
                state={{ dorple: dorple.building }}
                className="h-35 bg-sky-50"
              >
                <LazyLoadImage
                  className="h-35 w-full max-w-full object-cover object-center"
                  src={dorple.building.imageLink}
                  alt="gallery-photo"
                />
                   <progress className="[&::-webkit-progress-bar]:bg-green-600 [&::-webkit-progress-value]:bg-red-600 [&::-moz-progress-bar]:bg-red-600" value={dorple.building.scoreN} max={dorple.building.scoreN + dorple.building.scoreY}> </progress>
              </Link>
            </div>
          ))}
      </InfiniteScroll>
    }
    </div>
  );
}

export default GalleryPage;
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { SwipeCard } from "../components/SwipeCard";
import SwipeCongrats from '../components/SwipeCongrats';
import { useRequestService } from "../services/RequestService";
import { Puff } from "react-loader-spinner";

import { TiTick, TiTimes, TiRefresh } from "react-icons/ti";
// import { LazyLoadImage } from 'react-lazy-load-image-component';

function Swipe() {
  const [dorples, setDorples] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [showcardContainer, setShowcardContainer] = useState(true);
  const requestService = useRequestService();

  const currentIndexRef = useRef(currentIndex);
  const childRefs = useMemo(() => Array(dorples.length).fill(0).map(() => React.createRef()), [dorples]);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await requestService.get("buildings/swipe");

        if (response) {
          setDorples(response.content);
          setCurrentIndex(response.content.length - 1); // Stel de beginindex in zodra data is geladen
        } else {
          throw new Error("Failed to fetch buildings");
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [requestService]);


  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const sendScore = (userid, buildingId, direction) => {

    let score = direction === 'left' ? 'N' : 'Y';

    console.log(`user: ${userid} buildingid: ${buildingId} Score: ${score} `)

    try {
      requestService.post('scores', {user:{ id:  userid}, building: { id: buildingId }, scoring: score })
        .then(data => console.log("Score sent successfully:", data))
        .catch(error => console.error("Error sending score:", error));
    } catch (ex) {
      console.error(ex);
    }
  };

  const canGoBack = currentIndex < dorples.length - 1;
  const canSwipe = currentIndex >= 0;

  const swiped = (direction, buildingId, nameToDelete, index, currentIndex) => {
    updateCurrentIndex(index - 1);
    sendScore(localStorage.getItem('user-id'), buildingId, direction);

    if (currentIndexRef.current === -1) {
      setShowcardContainer(!showcardContainer)
    }

  };

  const outOfFrame = (name, idx) => {
    console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current, childRefs[idx].current);
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
  };

  const swipe = async (dir) => {
    if (canSwipe && currentIndex < dorples.length) {
      await childRefs[currentIndex].current.swipe(dir);
    }
  };

  const goBack = async () => {
    if (!canGoBack) return;
    const newIndex = currentIndex + 1;
    updateCurrentIndex(newIndex);
    await childRefs[newIndex].current.restoreCard();
  };

  return (
    <div>
      {showcardContainer ? <><div style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}>
        {dorples && dorples.length ? <div className='card-container mt-10'>
          {dorples.reverse().map((character, index) => ( // Reverse so that the first house is the one with lowest ID/index so that if the page is refreshed the stack continues where you left off.
            <SwipeCard
              ref={childRefs[index]}
              className='swipe'
              key={index}
              onSwipe={(dir) => swiped(dir, character.id, character.name, index)}
              onCardLeftScreen={() => outOfFrame(character.name, index)}
            >
              <div
                style={{ backgroundImage: `url(${character.imageLink})` }}
                className='card border grid grid-cols-1 gap-0 content-end'
              >
                <div className="flex flex-row  align-middle m-2 bg-white opacity-75 rounded-md">
                  <div className="m-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeLinejoin="1.5" stroke="currentColor" className="size-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819" />
                    </svg>
                  </div>
                  <div className="m-1 font-bold text-sm">{character.name}</div>
                </div>
                <div className="flex flex-row  align-middle m-2 mt-0 bg-white opacity-75 rounded-md">
                  <div className="m-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeLinejoin="1.5" stroke="currentColor" className="size-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                    </svg>
                  </div>
                  <div className="m-1 text-sm">{character.location}</div></div>
              </div>
            </SwipeCard>

          ))}
        </div>: <Puff
                visible={true}
                height="70"
                width="70"
                color="#0C4A6E"
                ariaLabel="puff-loading"
                wrapperStyle={{justifyContent: "center"}}
                wrapperClass=""
              />}

        

	      { showcardContainer ?<div className='buttons grid grid-flow-col justify-evenly'>
          <div>
            <button style={{ backgroundColor: !canSwipe && '#c3c4d3' }} className="rounded-full bg-red-600" onClick={() => swipe('left')}>
              <TiTimes className="w-8 h-8" />
            </button>
          </div>
          <div>
            <button style={{ backgroundColor: !canGoBack && '#c3c4d3' }} className="rounded-full bg-indigo-600" onClick={() => goBack()}>
              <TiRefresh className="w-8 h-8" />
            </button>
          </div>
          <div>
            <button style={{ backgroundColor: !canSwipe && '#c3c4d3' }} className="rounded-full bg-green-600" onClick={() => swipe('right')}>
              <TiTick className="w-8 h-8" />
            </button>
          </div>
        </div>
: ''
	      }




      </div>
      { showcardContainer ?
        <div className="z-40 w-full flex items-end">
        <progress id="swipebar" max={dorples.length} value={dorples.length - currentIndex -1} 
        
        className="inline-block [&::-webkit-progress-bar]:bg-sky-100 [&::-webkit-progress-value]:bg-sky-600 [&::-moz-progress-bar]:bg-sky-600"
        ></progress>
        </div>
        : ''
      }
      </> : <div><SwipeCongrats /></div>}
    </div>
  );
}

export default Swipe;

import React, { useState } from 'react';
import DorpleLogo from '../images/logo-large.svg';
import "@fontsource/raleway/700.css";

function HomePage() {

  const [username] = useState(() => {
    const saved = localStorage.getItem("username");
    return saved || "";
  });

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>

      <div className="w-48 max-w-48 pb-4">
        <img src={DorpleLogo} alt="Dorple Logo" />
      </div>

      <h1 className="text-6xl text-sky-800 apptitle font-bold">Dorple</h1>
      <p className="text-2xl text-sky-800 appsubtitle font-bold">voor GevelGeeks</p>
    </div>


  );
}

export default HomePage;

import { forwardRef, useRef, useLayoutEffect, useImperativeHandle, useState, useEffect, useCallback } from "react";
import { useSpring, animated } from "@react-spring/web";

const physics = {
  touchResponsive: {
    friction: 40,
    tension: 1500
  }
}

const settings = {
  maxTilt: 25, // in deg
  rotationPower: 50,
  swipeThreshold: 0.7,
  minimumVelocity: 3
}

export const swipeDirection = {
  up: "up",
  down: "down",
  left: "left",
  right: "right",
}

const getSwipeDirection = (property) => {
  // let swipeDirection = ;
  if (Math.abs(property.x) > Math.abs(property.y)) {
    if (property.x > settings.swipeThreshold) return 'right';
    else if (property.x < -settings.swipeThreshold) return 'left';
  } else {
    if (property.y > settings.swipeThreshold) return 'down';
    else if (property.y < -settings.swipeThreshold) return 'up';
  }
  return "none";
}

const pythagoras = (x, y) => {
  return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
}

const animateOut = async (gesture, setSpringTarget, windowHeight, windowWidth) => {
  const diagonal = pythagoras(windowHeight, windowWidth);
  let velocity = pythagoras(gesture.x, gesture.y);

  // const minimumVelocity = 10;
  if (velocity < settings.minimumVelocity) velocity = settings.minimumVelocity;

  const finalX = diagonal * gesture.x;
  const finalY = diagonal * gesture.y;
  const finalRotation = gesture.x * 45;
  const duration = diagonal / velocity;

  setSpringTarget.start({
    xyrot: [finalX, finalY, finalRotation],
    config: { duration: duration }
  });

  // for now animate back
  return await new Promise((resolve) => setTimeout(resolve, duration));
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}


const AnimatedDiv = animated.div

export const SwipeCard = forwardRef(({ children, onSwipe, preventSwipe = [swipeDirection.up, swipeDirection.down], onCardLeftScreen, swipeThreshold = settings.swipeThreshold }, ref) => {
  const element = useRef();

  settings.swipeThreshold = swipeThreshold;

  const { width, height } = useWindowSize();
  // const [clickOffset, setClickOffset] = useState({x: 0, y: 0});
  const [{ xyrot }, setSpringTarget] = useSpring(() => ({
    xyrot: [0, 0, 0],
    config: physics.touchResponsive
  }));

  let [isAnimatingBack, setAnimatingBack] = useState(false);

  const animateBack = (setSpringTarget) => {
    // translate back to the initial position
    return new Promise((resolve) => {
      setAnimatingBack(true);
      if (element.current) element.current.style.display = "block";
      setSpringTarget.start({
        xyrot: [0, 0, 0], config: physics.animateBack, onRest: () => {
          setAnimatingBack(false);
          resolve()
        }
      });
    })
  }

  useImperativeHandle(ref, () => ({
    async swipe(direction = swipeDirection.right) {
      if (onSwipe) onSwipe(direction);
      const power = 1.3;
      const disturbance = (Math.random() - 0.5) / 2;
      let gesture;
      if (direction === swipeDirection.right) gesture = { x: power, y: disturbance };
      else if (direction === swipeDirection.left) gesture = { x: -power, y: disturbance };
      else if (direction === swipeDirection.up) gesture = { x: disturbance, y: -power };
      else if (direction === swipeDirection.down) gesture = { x: disturbance, y: power };

      const wasAnimatingBack = isAnimatingBack;
      await animateOut(gesture, setSpringTarget, width, height);
      if (onCardLeftScreen) onCardLeftScreen(direction);
      if (element.current && !wasAnimatingBack && !isAnimatingBack) element.current.style.display = "none";
    },
    async restoreCard() {
      await animateBack(setSpringTarget);
    }
  }));

  const handleSwipeReleased = useCallback(
    async (setSpringTarget, gesture) => {
      // Check if this is a swipe
      const direction = getSwipeDirection({
        x: gesture.vx,
        y: gesture.vy
      });

      if (direction !== 'none') {
        if (!preventSwipe.includes(direction)) {
          if (onSwipe) onSwipe(direction)
          await animateOut({ x: gesture.vx, y: gesture.vy }, setSpringTarget, width, height);
          if (onCardLeftScreen) onCardLeftScreen(direction);
          if (element.current) element.current.style.display = "none";
          return;
        }
      }

      // Card was not flicked away, animate back to start
      animateBack(setSpringTarget)
    }, [preventSwipe, onSwipe, onCardLeftScreen, width, height]);

  // const [ position, setPosition ] = useState({x: 0, y: 0, rot: 0});


  const getEventCursorPosition = event => {
    if (event.touches && event.touches.length) event = event.touches[0];
    return { x: event.clientX, y: event.clientY };
  }

  useLayoutEffect(() => {

    let startPosition = { x: 0, y: 0 };
    let lastPosition;
    let isMouseDown = false;

    const setStartPostition = (x, y) => startPosition = { x, y };
    const setLastPostition = (dx, dy, vx = 0, vy = 0) => lastPosition = { dx, dy, vx, vy, timeStamp: Date.now() };
    setStartPostition(0, 0);
    setLastPostition(0, 0);

    const onMouseDown = event => {

      if (!event.touches) event.preventDefault();

      const { x, y } = getEventCursorPosition(event);
      setStartPostition(x, y);
      setLastPostition(x, y);

      if (element.current) {
        const offsetX = 1 / element.current.clientWidth * event.offsetX - 1;
        const offsetY = 1 / element.current.clientHeight * event.offsetY - 1;
        console.log(offsetX, offsetY, event);
      }

      isMouseDown = true;
    };

    const onMouseMove = event => {
      if (event.touches && event.touches.length === 2) return;
      event.preventDefault();
      if (!isMouseDown) return;
      const { x, y } = getEventCursorPosition(event);

      const
        dt = lastPosition.timeStamp - Date.now(),
        dx = x - startPosition.x,
        dy = y - startPosition.y,
        vx = -(dx - lastPosition.dx) / dt,
        vy = -(dy - lastPosition.dy) / dt;

      setLastPostition(dx, dy, vx, vy);

      let rot = vx * 13;
      if (isNaN(rot)) rot = 0;
      rot = Math.max(Math.min(rot, settings.maxTilt), -settings.maxTilt)
      setSpringTarget.start({ xyrot: [dx, dy, rot], config: physics.touchResponsive });
    }

    const onMouseUp = event => {
      if (!isMouseDown) return;
      handleSwipeReleased(setSpringTarget, lastPosition);
      isMouseDown = false;
    }

    const currentElement = element.current;
    window.addEventListener(('mouseup'), onMouseUp);
    window.addEventListener(('mousemove'), onMouseMove);
    currentElement.addEventListener(('mousedown'), onMouseDown);
    currentElement.addEventListener(('touchstart'), onMouseDown);
    currentElement.addEventListener(('touchmove'), onMouseMove);
    currentElement.addEventListener(('touchend'), onMouseUp);

    return () => {
      // console.log("I beem destroyed");
      window.removeEventListener(('mouseup'), onMouseUp);
      window.removeEventListener(('mousemove'), onMouseMove);
      currentElement.removeEventListener(('mousedown'), onMouseDown);
      currentElement.removeEventListener(('touchstart'), onMouseDown);
      currentElement.removeEventListener(('touchmove'), onMouseMove);
      currentElement.removeEventListener(('touchend'), onMouseUp);
      // setPosition({x: 0, y: 0, rot: 0});
      // element.current.removeEventListener() // destruct
    }
  }, [setSpringTarget, handleSwipeReleased]);


  return (
    <AnimatedDiv ref={element} style={{
      position: "absolute",
      transform: xyrot.to((x, y, rot) => `translate3d(${x}px, ${y}px, ${0}px) rotate(${rot}deg)`),
      // transformOrigin: `0% ${clickOffset.y}%`
    }}>
      {children}
    </AnimatedDiv>
  );
});

export const storageKeys = {
  token: "jwt",
  userId: "user-id",
  username: "username"
}

export default class StorageService {

  /**
   * Save 
   * @param {string} key The key to use to store the item.
   * @param {string | object} value The value to store.
   */
  save(key, value) {
    localStorage.setItem(key, value);
  }

  /**
   * Load item
   * @param {string} key The key of the stored item.
   */
  load(key) {
    return localStorage.getItem(key);
  }
  /**
   * Removes an item from storage
   * @param {string} key The key of the stored item.
   */
  remove(key) {
    localStorage.removeItem(key);
  }

  saveToken = token => this.save(storageKeys.token, token);
  saveUserId = userId => this.save(storageKeys.userId, userId);
  saveUsername = username => this.save(storageKeys.username, username);

  loadToken = this.load.bind(this, storageKeys.token);
  loadUsername = this.load.bind(this, storageKeys.username);
  removeToken = this.remove.bind(this, storageKeys.token);

}
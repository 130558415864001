import { useEffect, useState } from 'react';

import { useAuthenticationService}  from '../services/AuthenticationService';
import RequestService from '../services/RequestService';

export default function SwipeCongrats() {
  const authenticationService = useAuthenticationService();
  const [requestService] = useState(new RequestService());
  const [userStats, setUserStats] = useState();

  useEffect(() => {

  });

  return (
    <div style={{ textAlign: 'center', padding: '20px' }} className="flex flex-col items-center justify-center">

        <h1 className="text-4xl text-sky-800 apptitle font-bold  pt-10">Oops!</h1>
        <p className="text-lg text-sky-800 ">Je gallerij is nog leeg.<br /> Je kan ze vullen door te swipen.</p>

        <div className="flex w-full justify-center pt-10">
        <a href="/swipe" className="shadow bg-sky-800 hover:bg-sky-700 focus:shadow-outline focus:outline-none text-white text-md m-3 p-2  rounded">Start met Swipen</a>
      </div>


    </div>
  );
}
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRequestService } from '../services/RequestService';
import DorpleLogo from '../images/logo-large.svg';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const requestService = useRequestService();
  
  const handleLogin = async () => {
    try {
      if (!email || !password) {
        setError('Please enter both email and password.');
        return;
      }

      const result = await requestService.authenticationService.signIn(email, password);

      if (result) {
        console.log("Successfully logged in.", result);
        setEmail('');
        setPassword('');
        navigate('/home');
      } else {
        console.error("Failed to log in.", result);
        setError(result.message || 'Login failed');
        // setError(data.message || 'Login failed');
      }
    } catch (error) {
      console.error('Login failed:', error.message);
      setError('Invalid email or password.');
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 m-4">


    <div className="  w-20 h-20 max-w-20 pb-4 mb-4">
        <img className=" border-sky-800 =w-14 h-14 max-w-14 border rounded-full m-4" src={DorpleLogo} alt="Dorple Logo" />
    </div>




      <div className="border rounded-lg shadow-md p-4">
        <form className="login-panel" onSubmit={handleLogin}>
          <h2 className="mb-4 text-sky-900 text-center">Login</h2>
          <input
            className="border appearance-none  rounded m-3 p-2 text-gray-700 leading-tight focus:outline-none bg-white border-sky-500 focus:bg-sky-50 focus:border-sky-700"
            placeholder='Email address'
            id='email'
            value={email}
            type='email'
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            className="border appearance-none  rounded m-3 p-2 text-gray-700 leading-tight focus:outline-none bg-white border-sky-500 focus:bg-sky-50 focus:border-sky-700"
            placeholder='Password'
            id='password'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <p className="text-red-600">{error}</p>}
          <button
            type="button"
            className="shadow bg-sky-800 hover:bg-sky-700 focus:shadow-outline focus:outline-none text-white text-md m-3 p-2  rounded"
            onClick={handleLogin}
          >
            Sign in
          </button>
          <div className="text-center text-sm">
            <p>Not a member? <a href="/signup">Register</a></p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
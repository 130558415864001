import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthenticationService } from "../services/AuthenticationService";

function NavFooter() {
    const location = useLocation();
    const navigate = useNavigate();
    const authenticationService = useAuthenticationService();
    const [token] = useState(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        console.log('handle route change here', location, token);
        if (authenticationService.isTokenValid()) setShow(true);
        else {
            // Token is ongeldig of verlopen, log de gebruiker uit ok
            console.log("Token is verlopen. Uitloggen...");
            setShow(false);
            const hasToken = token;
            authenticationService.signOut();
            localStorage.removeItem("jwt-token");
            const whitelist = ["/login", "/register", "/signup", "/signin"]
            if (hasToken || whitelist.indexOf(location.pathname) === -1) navigate("/login");
        }
    }, [location, token, authenticationService, navigate]);

    return (show ?
        <div className="z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
            <div className="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
                <Link
                    to="/home"
                    className="nav-footer-link hover:bg-gray-50 dark:hover:bg-gray-800 dark:group-hover:text-sky-500 group"
                >
                    <svg
                        className="size-6 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                    >
                        <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
                        <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
                    </svg>
                    <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500 w-40">
                        Home
                    </span>
                </Link>
                <Link
                    to="/swipe"
                    className="nav-footer-link hover:bg-gray-50 dark:hover:bg-gray-800 dark:group-hover:text-sky-500 group"
                >
                    <svg
                        className="size-6 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                    >
                        <path d="M16.5 6a3 3 0 0 0-3-3H6a3 3 0 0 0-3 3v7.5a3 3 0 0 0 3 3v-6A4.5 4.5 0 0 1 10.5 6h6Z" />
                        <path d="M18 7.5a3 3 0 0 1 3 3V18a3 3 0 0 1-3 3h-7.5a3 3 0 0 1-3-3v-7.5a3 3 0 0 1 3-3H18Z" />
                    </svg>

                    <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500">
                        Swipe
                    </span>
                </Link>
                <Link
                    to="/gallery"
                    className="nav-footer-link hover:bg-gray-50 dark:hover:bg-gray-800 dark:group-hover:text-sky-500 group"
                >
                    <svg
                        className="size-6 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fillRule="evenodd"
                            d="M3 6a3 3 0 0 1 3-3h2.25a3 3 0 0 1 3 3v2.25a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6Zm9.75 0a3 3 0 0 1 3-3H18a3 3 0 0 1 3 3v2.25a3 3 0 0 1-3 3h-2.25a3 3 0 0 1-3-3V6ZM3 15.75a3 3 0 0 1 3-3h2.25a3 3 0 0 1 3 3V18a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-2.25Zm9.75 0a3 3 0 0 1 3-3H18a3 3 0 0 1 3 3V18a3 3 0 0 1-3 3h-2.25a3 3 0 0 1-3-3v-2.25Z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-sky-700 dark:group-hover:text-sky-600">
                        Gallery
                    </span>
                </Link>
                <Link
                    to="/settings"
                    className="nav-footer-link hover:bg-gray-50 dark:hover:bg-gray-800 dark:group-hover:text-sky-500 group"
                >
                    <svg
                        className="size-6 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                    >
                        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                    </svg>
                    <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-sky-700 dark:group-hover:text-sky-600">
                        Profile
                    </span>
                </Link>
            </div>
        </div> : <></>
    );
}

export default NavFooter;

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import HomePage from './pages/HomePage';
import SwipePage from './pages/SwipePage';
import GalleryPage from './pages/GalleryPage';
import BuildingDetailPage from './pages/BuildingDetailPage';
import SettingsPage from './pages/SettingsPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import NavFooter from './components/NavFooter';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/swipe" element={<SwipePage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/gallerydetail" element={<BuildingDetailPage />} />
        <Route path="/settings" element={<SettingsPage />} />
      </Routes>
      <NavFooter />
    </Router>
  );
}

export default App;

import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAccountService } from '../services/AccountService';
import { useAuthenticationService } from '../services/AuthenticationService';


function SettingsPage() {
  const accountService = useAccountService();
  const authenticationService = useAuthenticationService();
  const [userStats, setUserStats] = useState();
  const navigate = useNavigate();
  const [panel, setPanel] = useState("");

  const [username] = useState(() => {
    const saved = accountService.requestService.authenticationService.storageService.loadUsername();
    return saved || "";
  });


  useEffect(() => {
    if (!userStats) try {
      accountService.getStats().then(stats => setUserStats(stats));
    } catch (ex) {
      console.error("Failed to fetch stats.", ex);
    }
  });

  const signOut = () => {
    authenticationService.signOut();
    navigate(0);
  }

  const openNameChangePane = () => {
    setPanel("change-name");
  }

  const openSettingsStart = () => {
    setPanel();
  }

  const applyNameChange = () => {
    const newUsername = document.getElementById("username-input").value;
    try {
    authenticationService.requestService.post("account/name", {fullName: newUsername}, null, false);
    } catch (error) {
      console.error("Failed to change name:", error);
    }
    openSettingsStart();
  }

  return (() => {
    switch(panel) {
      default:
        return <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start'
        }}>
          <button onClick={openNameChangePane} className='p-3 w-full outline outline-1 outline-gray-300 content-center text-left select-none flex justify-between'>Naam: <span className='select-text'>{username}<i className="arrow right ml-2"></i></span></button>
          <p className='p-3 w-full outline outline-1 outline-gray-300 content-center text-left select-none flex justify-between'>E-mail: <span className='select-text'>{authenticationService.email}</span></p>
            <button className='p-3 w-full outline outline-1 outline-gray-300 content-center text-left select-none bg-red-400' onClick={signOut}>Sign out</button>
        </div>
        case "change-name":
          return<form className='settings-pane'>
            <button onClick={openSettingsStart} type='button'>Terug</button>
            <input id='username-input' type='text'/>
            <button onClick={applyNameChange} type='button'>Opslaan</button>
          </form>
    }
})();

}

export default SettingsPage;
